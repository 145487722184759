/*=============================================================================================
	Company    : PT Web Architect Technology - webarq.com
	Document   : Stylesheet
	Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
// @import "../component/popup";
@import "../pages/general";

/* home styling
----------------------------------------------------------------------------------------------*/
.home {
	position: relative; 
	.trigger {
		position: absolute;
		width: 100%;
		height: 1px;
		top: 250px;   
	}
	h3 {
		font-size: 3rem; 
		line-height: 1.2; 
	}
	.txtdate {
	color: $color-initial;
	font-size: 0.6875rem; 
	margin-bottom: 12px;
	font-weight: 600; 
	.date {
		
	}
	.cate {
		color: #FC1368;
		position: relative;
		padding-left: 14px;
		margin-left: 8px; 
		&:before {
			position: absolute;
			width: 6px;
			height: 6px;
			content: ""; 
			border-radius: 50%;
			background: #C4C4C4;
			top: 5px; 
			left: 0; 
		}
	}
  }
	&.bannerhome {      
		z-index: 8; 
		.list {
			display: flex !important;
			height: 100vh !important; 
			position: relative; 
			overflow: hidden;   
			align-items: center;        
			figcaption {
				position: relative; 
				width: 100%; 
			z-index: 5; 
			.wrapper {
				padding-left: 30px; 
				.inner {
					display: inline-block;
					width: 470px; 
				}
			}           
			h2 {
				font-family: 'Montserrat';
				font-size: 1.9375rem; 
				color: $color-initial; 
				font-weight: 600; 
			}
			p {
				color: #FDFBF5; 
				margin-bottom: 20px;
				line-height: 1.5;
				span {
					opacity: .7; 
				}
			}       
			}
			figure {
				width: 100%; 
				height: 100vh; 
				img {
					width: 100%;  
					height: 100%; 
				object-fit: cover;
				object-position: center;
				}   
			}   
			.ribbon-1 {
				position: absolute;
				z-index: 3; 
				&.ileft {
					left: 0; 
					top: 0; 
				}
				&.iright {
					right: 0;
					bottom: 50px; 
				}
			}
			.ribbon-2 {
				position: absolute;
				top: 0; 
				left: 0; 
				z-index: 5; 
			}   
			.ribbon-3 {
				position: absolute;
				bottom: 0; 
				left: 0; 
				z-index: 5; 
			}       
			&.type-2 {
				background: #402566;
				figure {
					background: #402566;
					width: 720px; 
					height: 780px; 
					position: absolute;                 
					right: 0;   
					top: 50%; 
					transform:translateY(-50%);
					span {
						display: block; 
						overflow: hidden;
						width: 100%;
						height: 100%; 
					}
					&:before {
						position: absolute;
						width: 100%; 
						height: 100%; 
						content: ""; 
						left: 0; 
						top: 0;                          
						background: url('/images/material/frameBanner2.png') no-repeat 0 0;
						background-size: cover; 
						background-position: center; 
						z-index: 4;
					}                   
					&:after {
						position: absolute;
						width: 100%; 
						height: 100%; 
						content: "";
						background: #402566;
						right: 100%; 
						top: 0; 
						z-index: 3; 
					}   
				}   
			}
			&.type-3 {
				background: #4DC4D6;
				figure {
					background: #4DC4D6;
					width: 720px; 
					height: 780px; 
					position: absolute;                 
					right: 0;   
					top: 50%; 
					transform:translateY(-50%);
					span {
						display: block; 
						overflow: hidden;
						width: 100%;
						height: 100%; 
					}
					&:before {
						position: absolute;
						width: 100%; 
						height: 100%; 
						content: ""; 
						left: 0; 
						top: 0;                          
						background: url('/images/material/frameBanner3.png') no-repeat 0 0;
						background-size: cover; 
						background-position: center; 
						z-index: 4;
					}                   
					&:after {
						position: absolute;
						width: 100%; 
						height: 100%; 
						content: "";
						background: #4DC4D6;
						right: 100%; 
						top: 0; 
						z-index: 3; 
					}   
				}   
			}
		}           
		&:after {
			position: absolute;
			width: 100%;
			height: 58px; 
			content: ""; 
			left: 0; 
			bottom: 0; 
			background: url('/images/material/bg-homeslider.png') no-repeat 0 0;
			background-size: cover; 
			background-position: center; 
		}
	}
	&.story {
		// background: #FCFAF2 url('/images/material/bg-story.png') repeat-x 0 0;
		background: #FCFAF2;
		padding-bottom: 140px; 
		.i-cocovanila {
			position: absolute;
			top: 0; 
			left: 0;
		}
		.i-stroberi {
			position: absolute;
			top: 0; 
			right: 0; 
		}
		.wrapper {
			max-width: 1110px; 
		}
		h1 {
			font-family: 'Montserrat', sans-serif;
			font-size: 1rem;
			margin-bottom: 30px; 
			color: $color-primary; 
		}
		h2 {
			text-align: center;             
		} 
		h3 {
			color: #FC1368;     
			b {
				color: #402566; 
			}
		}
		h6 {
			color: #000; 
			font-family: "Montserrat"; 
			font-size: .875rem; 
			font-weight: 500; 
		}
		p {
			margin-bottom: 30px; 
			color: $color-primary; 
		}
		.row {
			.column {
				&:nth-child(1) {
					padding-right: 100px; 
					padding-top: 35px; 
				}
			}
		}
		.logo-list {
			.list {}
			.slick-slider {
				padding: 0 45px; 
			}
			.slick-arrow {
				&.slick-prev {
					left: 0;
				}
				&.slick-next {
					right: 0 
				}
			}
		}
	
	}
	&.our-product {
		// background: url('/images/material/bg-h-product.png') no-repeat 0 0;
		background: $color-primary; 
		background-size: cover; 
		background-position: center; 
		padding-top: 80px; 
		padding-bottom: 50px;
		margin-bottom: 100px;  
		min-height: 666px; 
		z-index: 6;
		&:before {
			position: absolute;
			content: ""; 
			width: 100%;
			height: 85px;
			top: -85px; 
			left: 0; 
			background: url('/images/material/prod-top.png') no-repeat 0 0;
			background-size: 100% 100%; 
		}
		&:after {
			position: absolute;
			content: ""; 
			width: 100%;
			height: 85px;
			bottom: -85px; 
			left: 0; 
			background: url('/images/material/prod-bottom.png') no-repeat 0 0;
			background-size: 100% 100%; 
		}
		.graph {
			position: absolute;
			z-index: 3; 
			&.graphline {
				top: -60px; 
				left: 0; 
			}
			&.graphbox {
				bottom: -30px; 
				left: 0;
			}
			&.graphdot {
				bottom: -60px; 
				right: 0; 
			}
		}
		.slick-arrow {
			&.slick-prev {
				left: -62px;
			}
			&.slick-next {
				right: -62px; 
			}
		}
		.h-title {
			color: $color-initial;
			p {
				color: $color-initial; 
			}
		}
		.wrapper {
			z-index: 5; 
		}
		.product-list {
			.list {
				margin: 0;
			}
			.slick-slide {
				margin: 0 15px; 
			}
		}
	}
	&.news {
		background: #FCFAF2; 
		padding-top: 40px; 
		padding-bottom: 200px;  
		overflow: unset; 
		z-index: 5; 
		.wrapper {
			z-index: 6; 
		}
		.box-news {
			padding-bottom: 45px;
			margin-bottom: 130px;
			position: relative;
			z-index: 7; 
			.column {
				margin-bottom: 0;
			} 
			&:after {
				position: absolute;
				width: auto;
				height: 4px;
				content: ""; 
				left: 10px;
				right: 10px;  
				bottom: 0; 
				background: url('/images/material/line.png') repeat-x 0 0;
			}
		}
		.box-nice {
			align-items: center; 
			position: relative; 
			h3 {
				color: $color-secondary;
				font-size: 2.25rem; 
				b {
					color: $color-primary
				}
			}
			figcaption {
				padding: 0 0 0 10px;
				p {
					margin-bottom: 20px;
					padding-right: 140px; 
				}  
			};
			.column {
				&:nth-child(2) {
					padding-left: 25px;
				}
			}
			.wrap-video {
				position: relative;
			}
			.video {
				position: relative;
				.play {
					position: absolute;
					width: 65px;
					height: 70px;
					background: url('/images/material/play.png') no-repeat 0 0; 
					left: 50%;
					top: 50%;
					transform:translate(-50%, -50%); 
					margin-top: -15px;   
					cursor:pointer; 
				}
				&:before {
					position: absolute; 
					width: 100%;
					height: 420px;
					content: ""; 
					background: url('/images/material/frameVideo.png') no-repeat 0 0;
					background-size: contain;  
					border-radius: 30px;
				}
				&:after {
					position: absolute;
					left: 0;
					bottom:20px;  
					width: 143px;
					height: 404px;  
					content: ""; 
					background: url('/images/material/doll.png') no-repeat 0 0; 
					left: -50px; 
					bottom: -130px;
				}
			}   
			.wrap-yt{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 5;
				display: none;
				iframe{
					width: 100%;
					height: 100%;
				}
			}               
		}
		.graph-corn {
			position: absolute;
			left: 0; 
			bottom:100px; 
			z-index: 5; 
			&.parallax-scroll {
				margin-top: 350px; 
			}
		}
		.graph-chocolate {
			position: absolute;
			right: 0; 
			top:0; 
			&.parallax-scroll {
				margin-top: -30px; 
			}
		}
		.graph-watermelon {
			position: absolute;
			right: 0; 
			bottom:100px; 
			&.parallax-scroll {
				margin-top: 700px; 
			}
		}
		&:before {
			position: absolute;
			width: 100%;
			height: 100px;
			content: ""; 
			background: #FCFAF2;
			top: -100px;
			left: 0; 
		}       
		.h-title {
			h3 {
				color: $color-secondary; 
				margin-bottom: 0; 
				b {
					color: $color-primary; 
				}
			}
			.wrap-button {
				.button {
					color: $color-primary; 
				}
			}
		}
		.news-list {
			.list {
				background: $color-initial;
				border-radius: 10px; 
				display: flex;
				margin-bottom: 20px; 
				justify-content: space-between; 
				height: 110px;
				align-items:center; 
				position: relative; 
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);    
				&:before {
					position: absolute;
					width: 100%;
					height: 100%; 
					content: "";
					background: none;
					top: 0; 
					left: 0; 
					opacity: 0;
					visibility: hidden;
					z-index: -1;    
					border-radius: 10px;    
					transition:all .2s ease-out;
				}
				&:after {
					position: absolute;
					width: 100%;
					height: 100%;
					content: "";
					border:1px solid $color-secondary;
					border-radius: 10px; 
					top: 0;
					left: 0; 
					transition:all .2s ease-out;
					opacity: 0;
					visibility: hidden;
					z-index: -1; 
				}
				figure {
					border-radius: 10px 10px 0 0;
					flex:0 0 40%;
					max-width: 40%;
					height: 100%; 
					img {
						border-radius: 0 10px 10px 0px;
						width: 100%;
						height: 100%;
						object-fit:cover; 
						object-position:center;
					}
				}
				figcaption {
					padding: 15px 25px; 
					flex:0 0 60%;
					max-width: 60%;
					border-radius: 0 0 10px 10px; 
					.txtdate {
						color: $color-primary; 
						margin-bottom: 8px; 
					}
					h5 {
						font-family: "Montserrat"; 
						color: #333; 
						font-weight: 600; 
						font-size: .875rem; 
						margin-bottom: 0;
						display: -webkit-box;
					  -webkit-line-clamp: 3;
					  -webkit-box-orient: vertical;
					  overflow: hidden;
					}
					.wrap-button {
						a {
							font-weight: 600;
							color: $color-primary; 
							font-size: .875rem;  
						}
					}
				}   
				&:hover {
					&:before {
						opacity: 1;
						visibility: visible;                         
						box-shadow: 4px 4px 0px $color-secondary; 
					}
					&:after {
						opacity: 1;
						visibility: visible; 
					}
				}           
			}
			&.lg {
				height: 100%;
				.list {
					display: block; 
					flex-direction: column; 
					padding-bottom: 25px;  
					height: calc(100% - 20px);   
					margin-bottom: 0;                   
					figure ,
					figcaption {
						flex:0 0 100%;
						max-width: 100%;
					}
					figure {
						height: 330px;  
						img {
							border-radius: 10px 10px 0 0;  
						}
					}
					figcaption {
						h5 {                            
							font-size: 1.25rem;
							margin-bottom: 1rem; 
							line-height: 1.6;                            
						}
					}
				}
			}
		}
	}
	&.join {
		min-height: 480px; 
		// background: url('/images/material/bg-join.png') no-repeat 0 0;
		background: $color-primary; 
		margin-top: -70px;  
		background-size: cover; 
		margin-left: 34px; 
		margin-right: 34px; 
		border-radius: 10px; 
		z-index: 5; 
		&:before,
		&:after {
			position: absolute;
			content: ""; 
		}
		&:before {
			width: 251px; 
			height: 203px; 
			top: 0; 
			left: 0; 
			background: url('/images/material/join-line.png') no-repeat 0 0;
		}
		&:after {
			width: 131px; 
			height: 56px; 
			bottom: 54px; 
			left: 0; 
			background: url('/images/material/join-dot.png') no-repeat 0 0;
		}
		.ice-frame {
			position: absolute;
			right: -34px;  
			bottom:-112px; 
			&.parallax-scroll {
				margin-top: 200px; 
			} 
		}
		.wrapper {
			max-width: 1000px;
		}
		.row {
			align-items:center; 
			.column {

			}
		}
		figure {
			text-align: center; 
		}
		figcaption {
			text-align: center;
			color: $color-initial;
			padding: 0 35px; 
			h3 {
				display: flex; 
				flex-direction: column; 
				text-align: center; 
				span {
					position: relative;
				}
				b {
					position: relative; 
					padding: 0 40px; 
					&:before {
						position: absolute;
						content: ""; 
						background: url('/images/material/union-2.png') no-repeat 0 0;
						left: 0; 
						top: 0;
						width: 23px;
						height: 31px;
						transform:rotate(-180deg);  
					}
					&:after {
						position: absolute;
						content: ""; 
						background: url('/images/material/union-2.png') no-repeat 0 0;
						top:0;
						right: 0; 
						width: 23px;
						height: 31px;
					}
				}
			} 
			p {margin-bottom: 22px;}
		}
	}
	&.stay-connected {
		overflow: hidden; 
		z-index: 6; 
		.stay-icon {
			position: absolute;
			bottom: 0;  
			left: 0; 
			z-index: -1;
			width: 275px; 
		}
		.wrapper {
			padding: 85px 0 120px 0; 
		}
		.h-title {
			h3 {
				color: $color-secondary;
				margin-bottom: 20px; 
				font-size: 2.25rem; 
				b {
					color: $color-primary; 
				}
				&:before { 
					position: absolute;
					width: 210px;
					height: 77px;
					content: ""; 
					background: url('/images/material/h3-connect.png') no-repeat 0 0; 
					top: -15px;
				left: -50px;
					z-index: -1; 
				}
				&:after {display: none;}
			}
			p {
				a {
					&.mention {
						color: $color-secondary; 
					}
				}
			}
		}
		.box-connect {
			// display: flex;
		margin-right: calc((100vw - 1170px) / 2 * -1);
		.slider {}
		.slick-slide {
			width: 370px; 
			margin-right: 25px;
		}
		// .list {
		//  margin-right: 25px; 
		//  width: 370px;  
		// }
		.slick-arrow {
			&.slick-prev {
				left: -65px;
			}
			&.slick-next {
				right:22px;
			}
		}
		}
	}

}




/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}


/* 
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
	.home {
		&.bannerhome {
			.list {
				&.type-2,
				&.type-3 {
					figure {
						width: 520px; 
						height: 580px;  
					}
				}
			}
		}
		&.stay-connected {
			.stay-icon {
				width: 220px; 
			}
		}
	}
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.home {
		h3 {
			font-size: 2rem; 
		}
		&.bannerhome {
			.list {
				.ribbon-1 {
					&.ileft {
						img {
							width: 175px; 
						}
					}
					&.iright {
						img {
							height: 199px; 
						}
					}
				}
				.ribbon-2 {
					top: 70px; 
					width: 84px; 
				}
				.ribbon-3 {
					width: 115px; 
				}
				figcaption {
					.wrapper {
						padding-left: 135px; 
						max-width: 100%; 
						.inner {
							width: 390px; 
						}
					}
					h2 {
						font-size: 1.75rem; 
					}
				}
				&.type-2,
				&.type-3 {
					figure {
						width: 438px; 
						height: 489px;  
						&:before {
							background-size: 100% 100%; 
							top: -2px; 
							bottom: -2px;
							height: unset; 
						}
						&:after {
							right: 99%; 
						}
					}
				}
				
			}
		}
		&.story {
			padding-bottom:90px;  
			.i-cocovanila {
				width: 130px; 
			}
			.i-stroberi {
				width: 250px; 
			}
			.wrapper {
				max-width: 774px; 
			}
			h2 {
				img {
					width: 243px; 
				}
			}
			.row {
				.column {
					&:nth-child(1) {
						padding-right: 30px; 
					}
					&:nth-child(2) {}
				}
			}
		}
		&.our-product {
			min-height: 400px; 
			.graph {
				&.graphline {
					width: 392px; 
				}
				&.graphdot {
					width: 205px; 
				}
			}
			.h-title {
				p {
					font-size: .875rem; 
				}
			}
		}
		&.news {
			padding-bottom: 160px;
			.graph-chocolate {
				width: 103px; 
			}
			.graph-corn {
				width: 128px; 
			}
			.graph-watermelon {
				width: 117px; 
			}
			.news-list {
				.list {
					display: none;
					height: 100px; 
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						display: flex; 
					}
					figcaption {
						h5 {
							-webkit-line-clamp: 2; 
						}
					}
				}
				&.lg {
					.list {
						display: block; 
						figure {
							height: 190px; 
						}
						figcaption {
							h5 {
								margin-bottom: 0.5rem;
    						line-height: normal;
							}
						}
					}
				}
			}
			.box-news {
				margin-bottom: 65px; 
			}
			.box-nice {
				.video {
					&:after {
						width: 88px; 
						background-size: 100% auto;  
						height: 215px; 
						left: -30px;
					bottom: -50px;
					}
					.play {
						width: 37px;
					height: 40px;
						background-size: 100%; 
						margin-top: 0;  
					}
				}
				figcaption {
					p {
						padding-right: 0; 
					}
				}
				h3 {
					font-size: 1.5rem; 
				}
			}
		}
		&.join {
			margin-left: 32px;
			margin-right: 32px; 
			min-height: unset; 
			&:before {
				width: 160px;
			height: 100px;
			background-size: 100%;
			}
			&:after {
				width: 89px; 
				height: 36px; 
				background-size: 100%; 
				background-position: left bottom; 
			}
			figure {
				margin-left: 100px; 
				top: -45px; 
			}
			figcaption {
				padding: 0 55px 20px 0; 
				h3 {
					b {
						&:before,
						&:after {
							width: 20px; 
							height: 29px; 
							background-size: 20px; 
							top: 4px; 
						}
					}
				}
			}
			.ice-frame {
				width: 178px; 
			}
		}
		&.stay-connected {
			.h-title {
				h3 {
					font-size: 1.75rem; 
					&:before {
						display: none; 
					}
				}
				p {
					br {
						display: none;
					}
					a {
						&.mention {
							display: block; 
						}
					}
				}
			}
			.wrapper {
				padding: 65px 0 60px 0; 
			}
			.box-connect {
				margin-right: calc((99vw - 770px) / 2 * -1); 
				.stay-icon {
					width: 245px; 
				}
				.slick-slide {
					width: 248px; 
					margin-right: 15px; 
				}
			}
			.box-left {
				.logo-foot {
					margin-right: 0; 
					height: auto;                    
				}
			}
		}
		.stay-icon {
			width: 220px; 
		}

	}
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
	.home {
		h3 {
			font-size: 1.75rem; 
		}
		.txtdate {
			font-size: .625rem; 
		}
		&.bannerhome {
			.list {
				// height: 410px !important;
				height: auto !important;
				.ribbon-1 {
					&.ileft {
						width: 140px; 
					}
					&.iright {
						width: 48px; 
					}
				}
				.ribbon-2 {
					top:90px; 
					width: 60px; 
				}
				.ribbon-2 {
					width: 82px; 
				}
				figcaption {
					.wrapper {
						padding-left: 95px; 
						padding-top: 78px; 
						padding-bottom: 60px; 
						.inner {
							width: 299px; 
						}
					}
					h2 {
						font-size: 1.5rem; 
					}   
					p {
						display: -webkit-box;
					  -webkit-line-clamp: 3;
					  -webkit-box-orient: vertical;
					  overflow: hidden;
					}               
				}
				figure {
					height: auto; 
					img {
						height: auto; 
					}
				}
				&.type-2,
				&.type-3 {
					figure {
						width: 320px; 
						height: 324px; 
						img {
							height: 100%; 
						}
					}
				}
			}
		}
		&.story {
			padding-bottom: 120px; 
			.wrapper {
				max-width: 600px; 
			}
			.i-cocovanila {
				width: 102px; 
				margin-top: -50px; 
			}
			.i-stroberi {
				width: 188px; 
				margin-top: -25px; 
			}
			.row {
				// align-items: flex-end; 
				.column {
					&:nth-child(1) {
						padding-top: 0; 
						padding-right: 10px; 
					}
					&:nth-child(2) {}
				}
			}
			.logo-list {
				.slick-slider {
					padding: 0 30px; 
				}
				.slick-slide {
					margin: 0 2px; 
				}
			}
		}
		&.our-product {
			padding-top: 50px; 
			.h-title {
				p {
					font-size: .75rem; 
				}
			}
			.wrapper {
				width: 604px; 
			}
			.slick-arrow {
				&.slick-prev {
					left: -50px;  
				}
				&.slick-next {
					right: -50px; 
				}
			}
			&:before {
				height: 67px; 
				background: url(/images/material/prod-top-md.png) no-repeat 0 bottom;
				background-size: 100% 100%; 
				top: -67px; 
			}
			&:after {
				background: url(/images/material/prod-bottom-md.png) no-repeat 0 top;				
				background-size: 100% 100%; 
				height: 52px; 
				bottom: -52px; 
			}
			.graph {
				&.graphbox {
					display: none; 
				}
			}
		}
		&.news {
			padding-top: 20px; 
			padding-bottom: 120px; 
			.wrapper {
				max-width: 600px; 
			}
			.graph-chocolate {
				width: 92px; 
			}
			.graph-corn {
				width: 100px; 
			}
			.graph-watermelon {
				width: 100px; 
				img {
					width: 100%; 
				}
				&.parallax-scroll {
					margin-top: 500px; 
				}
			}
			.box-news {
				width: calc(100% + 16px); 
				margin-left: -8px; 
				margin-bottom: 40px; 
				padding-bottom: 20px; 
				.column {
					padding: 0 8px;
					margin-bottom: 16px; 
				}
				.box-nice {
					.video {
						.play {
							width: 30px; 
							height: 32px; 
						}
					}
				}
			}
			.news-list {
				.list {
					height: 77px; 
					margin-bottom: 16px; 
					figure {}
					figcaption {
						padding: 12px 16px; 
						h5 {
							font-size: 0.75rem; 
						}
						.wrap-button {
							a {
								font-size: .75rem; 
							}
						}
					}
				}
				&.lg {
					.list {
						figure {
							height: 143px; 
						}
						figcaption {
							h5 {
								font-size: 1rem; 
								line-height: 1.2; 
								margin-bottom: 6px; 
							}
						}
					}
				} 
			}

			.box-nice {
				h3 {
					font-size: 1.25rem;
				}
				.video {
					&:after {
						display: none; 
					}
				}
			}

		}
		&.join {
			&:after {
				width: 40px; 
				height: 20px; 
			}
			.ice-frame {
				width: 138px; 
				right: -32px; 
			}
			.wrapper {
				max-width: 700px; 
				padding: 30px 0; 
			}
			.row {
				.column {
					&:nth-child(1) { 
						flex:0 0 42%;
						max-width: 42%; 
					}
					&:nth-child(2) {
						 flex:0 0 58%;
						 max-width: 58%; 
					}
				}
			}
			figure {
				margin-left: 60px; 
			}
			figcaption {
				padding-right: 35px; 
				p {
					font-size: .865rem; 
				}
			}
		}
		&.stay-connected {
			.stay-icon {
				bottom: -5px;
			left: 0;
			z-index: -1;
			width: 125px;
			}
			.h-title {
				.wrap-button {
					position: absolute; 
					right: 50px; 
					top: 50%; 
					transform: translateY(-50%);    
				}
				.txt {
					p {
						padding-right: 225px; 
					}
				}
			}
			.box-connect {
				margin-right: calc((100vw - 604px) / 2 * -1);
				.slick-slide {
					width: 180px;
				}
				.slick-arrow {
					&.slick-prev {
						left: 25px; 
					}
				}               
			}           
		}
	}
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.home {
		h3 {
			font-size: 1.5rem; 
		}
		h6 {
			font-size: .75rem; 
		} 
		.h-title {
			flex-wrap:wrap;
			.txt {
				width: 68%;
				padding-right: 10px;
				h3 {
					b {
						display: inline-block; 
					}
					&:after {
						top: unset; 
						bottom: 0; 
					}
				} 
			}
			.wrap-button {
				width: 32%; 
				.button {
					white-space: normal;
				line-height: 1.4;
				height: auto;
				&:before {
					top: 50%; 
					transform: translateY(-50%); 
				}
				}
			}
		}
		&.bannerhome {
			&:after {
				background: url('/images/material/bg-homeslider-small.png') no-repeat center 0;
				background-size: 100%; 
				height: 30px; 
			}
			.list {
				// height: 497px !important;  
				height: auto !important;  
				display: flex !important; 
				flex-direction: column; 
				.ribbon-1 {
					&.ileft {
						width: 133px; 
					}
					&.iright {
						width: 45px; 
						top: 90px; 
						bottom: unset; 
					}
				}
				.ribbon-2 {
					width: 58px; 
				}
				.ribbon-3 {
					width: 52px; 
				} 
				figcaption {
					position: relative; 
					order: 2;
					top: unset;
				transform: unset;
				margin-bottom: 25px; 
				p {
					margin-bottom: 15px; 
				}
					.wrapper {
						padding: 0 16px; 
					}
					h2 {
						font-size: 1.125rem;  
						margin-bottom: 8px; 
					}
				}
				figure {
					order: 1; 
				} 
				&.type-2, 
				&.type-3 {
					figure {
						width: 236px; 
					height: 239px;
					left: unset;
					right: unset;
					transform: scale(1.4); 
					top: unset;
					position: relative;
					margin: 25px auto 10px auto;
					span {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					img {
						height: 70%;
						width: 70%;
					}
					}
				} 
				&.type-2 {
					figure {
						&:before {
							background: url('/images/material/frameBanner2-small.png') no-repeat center center;
							background-size: 276px; 
						}
					}
				}
				&.type-3 {
					background: #83d6e2; 
					figure {
						background: #83d6e2; 
						&:before {
							background: url('/images/material/frameBanner3-small.png') no-repeat center center;
							background-size: 276px; 
						}
						&:after {
							background: #83d6e2; 
						}
					}
				}
			}
			.slick-dots {
				bottom: 15px;
				display: none !important;   
				li {
					transform: scale(.7);
					margin-right: 5px;  
				}
			}
		}
		&.story {
			h2 {
				img {
					width: 182px; 
				}
			}
			.i-cocovanila {
				width: 82px; 
			}
			.i-stroberi {
				width: 115px; 
				margin-top: 25px; 
			}
			.wrapper {
				max-width: none;
				width: auto;
				margin-left: 24px; 
				margin-right: 24px;  
			}
			.row {
				.column {
					&:nth-child(1) {
						flex:0 0 100%; 
						max-width: 100%; 
						margin-bottom: 30px; 
					}
					&:nth-child(2) {
						flex:0 0 100%; 
						max-width: 100%;
					}
				}
			}
		}
		&.our-product {
			padding-top: 35px; 
			padding-bottom: 35px; 
			margin-bottom: 50px; 
			&:before {
				height: 50px;
				top: -50px;
				background: url(/images/material/prod-top-sm.png) no-repeat 0 bottom;
				background-size: 100% 100%; 
			}
			&:after {
				height: 43px;
				bottom: -43px;
				background: url(/images/material/prod-bottom-sm.png) no-repeat 0 bottom;
			}           
			.wrapper {
				width: auto; 
				margin: 0 24px; 
			}
			.product-list {
				.list {
					width: 140px !important;
				margin: auto;
				display: block !important;
				}
			}
			.graph {    
				&.graphline {
					width: 200px;
				left: 0;
				top: -30px;
				}
				&.graphbox {
					width: 50px; 
				}
				&.graphdot {
					width: 115px;
				bottom: -30px;
				}
			}
			.slick-arrow {
				&.slick-prev {
					left: 0;
				}
				&.slick-next {
					right: 0; 
				}
			}
		}
		&.news {
			&:before {
				top: -87px; 
			}
			.h-title {
				.txt {
					h3 {
						b {
							display: block; 
						}
					}
				}
			}           
			.graph-corn {
				width: 68px;
				&.parallax-scroll {
					margin-top: 100px;
				}
			}
			.graph-watermelon {
				width: 80px;
				margin-top: 600px; 
			}
			.box-news {
				margin-bottom: 32px;
			padding-bottom: 10px;
				.column {
					flex: 0 0 100%;
				max-width: 100%; 
				}
			}
			.news-list {
				.list {
					&:before {
						background:#fff; 
						opacity: 1; 
						visibility: visible; 
					}
					figcaption {
						flex:0 0 57%;
						max-width: 57%;
					}
					figure {
						flex:0 0 36%;
						max-width: 36%;  
					}
				}
				&.lg {
					.list {
						padding-bottom: 0; 
						figure {
							height: 155px; 
						}
					}
				}
			}
			.box-nice {
				h3 {
					margin-bottom: 10px; 
				}
				.column {
					flex:0 0 100%;
					max-width: 100%; 
					&:nth-child(1) {}
					&:nth-child(2) {
						padding-left: 0; 
						margin-bottom: 24px; 
					}
				}
				figcaption {
					padding: 0; 
				}
			}
		}
		&.join {
			.wrapper {
				max-width: none; 
			}
			.row {
				.column {
					&:nth-child(1) {
						flex: 0 0 100%;
					max-width: 100%;
					}
					&:nth-child(2) {
						flex: 0 0 100%;
					max-width: 100%;
					}
				}
			}
			figure {
				margin-left: 0; 
			}
			figcaption {
				padding-right: 0; 
				h3 {
					b {
						padding: 0 30px; 
						&:before,
						&:after {
							top: 0; 
						}
					}
				}
				p {
					font-size: .75rem; 
				}
			}
			.ice-frame {
				width: 118px; 
				&.parallax-scroll {
					margin-top: 330px; 
				}
			}
		}
		&.stay-connected {
			.h-title {
				h3 {
					font-size: 1.5rem;
				margin-bottom: 10px;
				}
				.txt {
					width: 100%; 
					padding-right: 0; 
					p {
						padding-right: 0; 
						margin-bottom: 16px; 
					}
				}
				.wrap-button {
					position: relative;
				right: 0;
				top: 0;
				transform: unset;
				width: 100%;
				}
			}
			.box-connect {
				margin-right: -24px; 
				.slick-arrow {
					&.slick-prev {
						left: 15px;
					}
					&.slick-next {
						right: 15px; 
					}
				}
			}
			.wrapper {
				padding-top: 60px; 
				padding-bottom: 48px; 
			}
			.stay-icon {

			}			
		}
	}
}